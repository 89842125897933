@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/antd/style';
@import 'assets/styles/bootstrap/style';
@import 'assets/styles/airui/style';
@import 'assets/styles/nprogress/style';
@import 'assets/styles/chartist/style';
@import 'assets/styles/chartist-tooltip-plugin/style';
@import 'assets/styles/jvectormap/style';
@import 'assets/styles/react-sortable-tree/style';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import 'assets/styles/react-draft-wysiwyg/style';
@import 'assets/styles/ynomia_styles/';



button.ant-btn.ant-btn-link.ant-btn-sm {
    visibility: hidden;
}
