.table {
  &-header-no-bg {
    thead > tr > th {
      background: transparent;
      border-bottom: none;
      color: $gray-5;
      padding-top: 2rem;
      text-transform: uppercase;
    }
  }
}

.fixed-height-table {
  background: #fbfbfb;
}

.fixed-height-table {
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
    overflow: inherit;
  }
}
