.custom-popover {
  &.black {
    .ant-popover-content {
      .ant-popover-inner .ant-popover-inner-content {
        background-color: black;
        color: white;
        padding: 4px 8px;
        font-size: 0.9rem;
      }
      .ant-popover-arrow {
        border-right-color: black;
        border-bottom-color: black;
      }
    }
  }
}
