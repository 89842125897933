// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-radio-wrapper,
.ant-checkbox-wrapper {
  color: $text;
}

.ant-form-item-label > label {
  color: $black;
}

.ant-picker-dropdown .ant-picker-footer .ant-picker-now {
  display: none;
}

.ant-table-filter-dropdown > .ant-dropdown-menu > li.ant-dropdown-menu-item {
  display: flex;
}

.ant-checkbox-wrapper {
  display: flex;

  .ant-checkbox {
    height: 16px;
    margin-top: 5px;
  }
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-explain {
  min-height: 28px;

  & > div {
    padding-bottom: 5px;
  }
}
