$font-24: 24px;
$font-18: 1.25rem;

.font-24 {
  font-size: $font-24;
}

.font-18 {
  font-size: $font-18;
}

.text--600 {
  font-weight: 600;
}

.warning {
  color: $red;
}
