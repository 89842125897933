// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-1;
}

.ant-input::placeholder,
.ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  color: rgb(195, 202, 206) !important;
  opacity: 1;
  font-size: 15px;
}

.ant-picker-time-panel-column {
  overflow-y: scroll;
}
