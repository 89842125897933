// Import mixins
@import 'assets/styles/mixins.scss';

/* ynomia overrides */
.ynomia-antd-grouped-table {
  table > thead > tr > th.emphasised-column-header {
    font-weight: 600;
  }
}

@include media-breakpoint-only(lg) {
  .dynamic-horizontal-scroll {
    overflow: scroll hidden;
  }
}
